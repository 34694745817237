import React from "react";
import ApplicationUpdater from "./application/updater";

const Updaters = () => (
  <>
    <ApplicationUpdater />
  </>
);

export default Updaters;
