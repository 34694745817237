import React from "react";

import Nav from "./components/Nav";
const TopBar: React.FC = () => {
  return (
    <>
      <Nav />
    </>
  );
};

export default TopBar;
